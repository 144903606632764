import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  user: any = (this.cookie.get('ud') != '') ? JSON.parse(this.cookie.get('ud')): null;
  imageProfile: any = 'assets/img/editProfile.png';

  constructor(private api: ApiService, private cookie: CookieService, private router:Router) { }

  ngOnInit() {

      if(this.user){
        this.imageProfile = this.user.userDetails.image;
      }

  }

  onClickMenu(e){

    $('#nav').hide();
    $('#menu2').show();   

  }

  exit(){

    if (this.cookie.get('ud') != ''){
      let data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        service: 'auth/logout'
      }
      this.api.api(data).subscribe((result:any)=> {      
        if (result.success){
          this.cookie.set('ud', '');
          this.router.navigate(['/login']);
        }
      },
        error => {
          if (error.error.message == 'Token has expired'){
            this.cookie.set('ud', '');
            this.router.navigate(['/login']);
          }else{
            console.log(error);
          }        
        });
    }else{
      this.cookie.set('ud', '');
      this.router.navigate(['/login']);
    }

  }
  
}
