import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, finalize, tap, throttleTime } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class GlobalHttpInterceptorServiceService implements HttpInterceptor{
  private throttleLogout = new Subject();

  constructor(private router: Router) {
    this.throttleLogout.pipe(throttleTime(5000)).subscribe((url) => {
      sessionStorage.setItem("ud", "");
      this.router.navigate(["/"]);
    });
  }


    intercept(
      request: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      const sessionData = sessionStorage.getItem("ud")
        ? JSON.parse(sessionStorage.getItem("ud"))
        : null;
      if (sessionData && sessionData.token) {
        request = request.clone({
          setHeaders: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionData.token}`,
          },
        });
      }
      return next.handle(request).pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            this.throttleLogout.next();
          }
          return throwError(response);
        })
      );
    }
}
