import { Pipe, PipeTransform } from "@angular/core";

const PADDING = "000000";

@Pipe({
  name: "thousands",
})
export class ThousandsPipe implements PipeTransform {
  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    this.DECIMAL_SEPARATOR = ".";
    this.THOUSANDS_SEPARATOR = ",";
  }

  transform(value: any | string, fractionSize: number = 0): string {
    let [integer, fraction = ""] = (value || "")
      .toString()
      .split(this.DECIMAL_SEPARATOR);

    fraction =
      fractionSize > 0
        ? this.DECIMAL_SEPARATOR +
          (fraction + PADDING).substring(0, fractionSize)
        : "";

    integer = integer
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, this.THOUSANDS_SEPARATOR);

    return integer + fraction;
  }
}
