import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private cookie: CookieService
  ) { }

  getUd = () => JSON.parse(this.cookie.get('ud'));

  getToken = () => this.getUd().token;

  getMerchantCode = () => this.getUd().MerchantActive[0].MerchantData.MerchantCode;
}
