import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { element } from 'protractor';


@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'));
  public allCashinOrdersDataTmp: any = this.allCashinOrders(this.cookie.get('merchant'));
  public filters: any = this.getFilters();
  public titlePage: string = 'Cash Out > Nóminas';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {

  }

  ngOnInit() {
    this.changeMerchant();
    console.log(this.MerchantActive);
    this.api.validateMerchantPermissions();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.allCashinOrdersDataTmp = this.allCashinOrders(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: merchant,
      service: 'cashout/payroll/',
      data: []
    }

    let headers = [
      {
        'name': 'ID',
        'key': 'PublicID',
        'opc': true
      },

      {
        'name': 'Total registros',
        'key': 'TotalRecords',
        'opc': true
      },
      {
        'name': 'Monto total',
        'key': 'TotalAmount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Fecha creación',
        'key': 'created_at',
        'opc': true
      },

      {
        'name': 'Total Creados',
        'key': 'TotalCreate',
        'opc': true
      },
      {
        'name': 'Total Errores',
        'key': 'TotalError',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        'opc': false,
        'functions': ['Aprobar']
      },
    ]

    if (dataInfo !== false) {
      data.service = 'cashout/payroll/WithParams';
      data.data = dataInfo;
    }
    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'cashout-payroll'
    }

  }



  async getFilters() {

    return [
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': this.api.getStatusPayroll()
      }

    ];

  }

  changeDataTable(data) {


    if (data.type && data.type === 'search') {
      if(data.data === "") {
        this.allCashinOrdersData = this.allCashinOrdersDataTmp;
        this.allCashinOrdersDataTmp = this.allCashinOrdersData;
      }else{
        this.allCashinOrdersData = [];
        this.search(this.allCashinOrdersDataTmp, data.data);
      }
    } else {
      this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), data);
      this.allCashinOrdersDataTmp = this.allCashinOrders(this.cookie.get('merchant'), data);
    }
  }


  search(array, word) {

    word = word.toLowerCase();

    var res = [];
    var headers = [];

    if (array.headers) {

      headers = array.headers;
      var index = 0;
      var itemsProcessed = 0;
      array.data.forEach(element => {

        for (var k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > -1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof (element[k]) == 'object') {
                for (var x in element[k]) {
                  for (var i in element[k][x]) {
                    if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++
        if (itemsProcessed == array.data.length) {
          this.allCashinOrdersData = {
            data: res,
            headers: headers,
            source: 'cashout-payroll'
          };
        }

        index++;
      });

    } else {

      array.then((e: any) => {

        headers = e.headers;
        var index = 0;
        var itemsProcessed = 0;
        e.data.forEach(element => {
          for (var k in element) {
            if (element.hasOwnProperty(k)) {


              if (element[k] && element[k].toString().toLowerCase().indexOf(word) > -1) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof (element[k]) == 'object') {
                  for (var x in element[k]) {
                    for (var i in element[k][x]) {
                      if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++
          if (itemsProcessed == e.data.length) {
            this.allCashinOrdersData = {
              data: res,
              headers: headers,
              source: 'cashout-payroll'
            };
          }

          index++;
        });
      });
    }

  }

  export() {

    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashout/transactions/download/',
      data: {
        from_date: $('#f_start').val(),
        to_date: $('#f_end').val(),
      }
    }

    this.api.api(data);
    $('#charging').addClass('hide');
  }

}
