import { ActivatedRoute } from '@angular/router';
import { SessionService } from './../../../../services/session.service';
import { ExtractService } from './../../../../services/extract.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import filters from "../../../../../assets/config/filters-extract.js";
import * as moment from "moment";

@Component({
  selector: 'app-consignments',
  templateUrl: './consignments.component.html',
  styleUrls: ['./consignments.component.scss']
})
export class ConsignmentsComponent implements OnInit {

  formFilter: FormGroup;
  records: any;
  loading: boolean = true;
  merchant: string = null;
  filters: any;
  today = new Date();
  metadata: any = null;

  constructor(
    private fb: FormBuilder,
    private extractService: ExtractService,
    private sessionService: SessionService,
    private route: ActivatedRoute
  ) { 
    this.merchant = this.sessionService.getMerchantCode();
    this.filters = this.getFilters();
  }

  ngOnInit() {
    
    this.buildFormFilters();
    this.route.queryParams.subscribe( params => {
      this.formFilter.controls['from_date'].setValue(params.from_date ? params.from_date :  this.formFilter.get('from_date').value);
      this.formFilter.controls['to_date'].setValue(params.to_date ? params.to_date :  this.formFilter.get('to_date').value);
    });
    this.getData();
  }

  buildFormFilters() {
    const year = this.today.getFullYear();
    const month = this.today.getMonth();
    this.formFilter = this.fb.group({
      from_date: new FormControl(
        moment().startOf("month").format("YYYY-MM-DD")
      ),
      to_date: new FormControl(moment().format("YYYY-MM-DD")),
      page: new FormControl(""),
      size: new FormControl("10"),
      MerchantType: new FormControl("6"),
      Type: new FormControl("1"),
      MerchantCode: new FormControl(this.merchant)
    });
  }

  getFilters() {
    return new Promise(( resolve, reject ) => {
      resolve(filters);
    });
  }
  
  getData() {
    this.extractService
      .getDetails(this.formFilter.value)
      .subscribe((response: any) => {
        const { data, ...metadata } = response;
        this.records = data;
        this.metadata = metadata
        this.loading = false;
      });
  }

  downloadData() {
    this.extractService
      .downloadRecords(this.formFilter.value)
      .subscribe((response: any) => {
        var newBlob = new Blob([response]);
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        const date = new Date();
        link.download = `Consignaciones.${date.toISOString()}.csv`
        link.href = url;
        link.click();
      });
  }

  changeDataTable(props) {
    this.formFilter.controls.from_date.setValue(props.from_date);
    this.formFilter.controls.to_date.setValue(props.to_date);
    this.getData();
  }

  changeIndex( page ) {
    this.loading = true;
    this.formFilter.controls['page'].setValue(page);
    this.getData();
  }

  changeSize( size ) {
    this.loading = true;
    this.formFilter.controls['size'].setValue( size );
    this.getData();
  }
}
