import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  response: any = false;
  public isLogged = false;
  formLogin: FormGroup;
  validateCaptcha = false;
  lang = 'es';
  isLoading = false;

  constructor(private router: Router, private cookie: CookieService, private authService: AuthService, public translate: TranslateService) {
    this.formLogin = this.createFormGroup();
   }

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  onLogin(): void {

    this.isLoading = true;
    if (this.validateCaptcha) {

      const data = {
        email: this.formLogin.get('email').value,
        password: this.formLogin.get('password').value,
        service: 'auth/login'
      };

      this.authService.api(data).subscribe((result: any) => {
        if (result.success !== 'Error en credenciales') {
          this.isLoading = false;
          this.cookie.set('ud', JSON.stringify(result));
          this.cookie.set('merchant', result.MerchantActive[0].MerchantData.MerchantCode);
          this.router.navigate(['/']);
        } else {
          this.isLoading = false;
          this.response = 1;
          // 'Error en credenciales';
        }
      },
        error => {
          console.log(error);
          this.isLoading = false;
          if (error.status === 401) {
            this.response = 2;
            // 'Error en el correo o contraseña'
          }
        });
    } else {
      this.isLoading = false;
      this.response = 3; // 'Valide el recaptcha por favor'
    }

  }


  ngOnInit() {
    this.verifySession();
  }

  verifySession() {
    if (this.cookie.get('ud') !== '') {
      this.isLogged = true;
      this.router.navigate(['/']);
    }
  }

  onResetForm() {
    this.formLogin.reset();
  }

  seePassword(e) {
    if ($('#password').attr('type') === 'password') {
      $('#password').attr('type', 'text');
    } else {
      $('#password').attr('type', 'password');
    }
  }


  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) {
      this.validateCaptcha = true;
    }
  }


  changeLang(language) {
    this.lang = language;
    this.translate.use(language);
  }

  get email() { return this.formLogin.get('email'); }
  get password() { return this.formLogin.get('password'); }

}
