import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  lang: any = this.translate.getDefaultLang();

  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'), { status: 14 });  //SOLO STATUS 14
  public filters: any = this.getFilters();
  public titlePage: string = 'Cash In > Pagos';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {

  }

  ngOnInit() {
    this.changeMerchant();
    console.log(this.MerchantActive);
    this.translateChange();
    this.api.validateMerchantPermissions();
  }
  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'en') {
        this.lang = 'en';
      } else if (event.lang == 'es') {
        this.lang = 'es';
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data, { status: 14 }); //SOLO STATUS 14
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: merchant,
      service: 'cashin/transactions/',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'PublicId',
        'opc': true
      },

      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'CustomerName',
        'opc': true
      },
      {
        'name': 'Código de pago',
        'key': 'PaymentCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Expiración',
        'key': 'TxExpiration',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      // {
      //   'name': 'Opciones',
      //   'key': 'opc',
      //   'opc': false,
      //   'functions': ['Ver detalle']
      // },
    ];

    if (dataInfo !== false) {
      data.service = 'cashin/transactions/WithParams';
      data.data = dataInfo;
    }
    const result = await this.api.api(data).toPromise() as any[];
    result.forEach((item, i) => {
      let laguage = 'es-CO';
      if (item.CurrencyCode === 'PEN') {
        laguage = 'es-PE';
      }
      if (item.CurrencyCode === 'USD') {
        laguage = 'en-US';
      }
      const formatter = new Intl.NumberFormat(laguage, {
        style: 'decimal',
        currency: item.CurrencyCode,
        minimumFractionDigits: 2
      });
      result[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
      //result[i].Amount = formatter.format(item.Amount);
    });
    return {
      headers: headers,
      data: result,
      source: 'cashin-payments-orders'
    };

  }



  async getFilters() {

    return [
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      // {
      //   'name': 'Estado',
      //   'key': 'status',
      //   'type': 'select',
      //   'data': this.api.getStatus()
      // }

    ];

  }

  changeDataTable(data) {
    data.status = 14; // SOLO STATUS 14
    this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), data);
  }

  export() {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashin/transactions/download/',
      data: {
        from_date: $('#f_start').val(),
        to_date: $('#f_end').val(),
        status: 14,  // SOLO STATUS 14
        language: this.lang
      }
    };

    this.api.api(data);
    $('#charging').addClass('hide');
  }

}
