import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  public mDashboard = false;
  public mCashinOrders = false;
  public mCashinReportsSettelement = false;
  public mCashinReportsPayments = false;
  public mCashoutOperations = false;
  public mCashoutPayroll = false;
  public mCashoutReportsSettelement = false;
  public mConciliations = false;
  public mCustomers = false;
  public mBankAcounts = false;
  public mConfiguration = false;
  public mExtract = false;
  public mCashoutConsignments = false;

  constructor(private router: Router, private api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    this.marginNav();
    this.getMenuByPathName();
    this.api.validateMerchantPermissions();
  }

  openMap: { [name: string]: boolean } = {
    sub1: false,
    sub2: false,
    sub3: false,
    sub4: false,
    sub5: false,
    sub6: false,
    sub7: false
  };


  getMenuByPathName() {

    const pathname = window.location.pathname;

    if (pathname === '/') {
      this.mDashboard = true;
    }

    if (pathname === '/cashin-payments-orders' || pathname.indexOf('cashin-payments-orders') > -1) {
      this.openMap.sub1 = true;
      this.mCashinOrders = true;
    }

    if (pathname == '/cashin-reports-settlement' || pathname.indexOf('cashin-reports-sttelement') > -1) {
      this.openMap.sub1 = true;
      this.openMap.sub4 = true;
      this.mCashinReportsSettelement = true;
    }

    if (pathname == '/cashin-reports-payments') {
      this.openMap.sub1 = true;
      this.openMap.sub4 = true;
      this.mCashinReportsPayments = true;
    }

    if (pathname == '/cashout-operations' || pathname == '/import' || pathname.indexOf('cashout-operations')> -1) {
      this.openMap.sub2 = true;
      this.mCashoutOperations = true;
    }


    if (pathname == '/payroll' || pathname == '/new-payroll') {
      this.openMap.sub2 = true;
      this.mCashoutPayroll = true;
    }

    if (pathname == '/cashout-reports') {
      this.openMap.sub2 = true;
      this.openMap.sub5 = true;
      this.mCashoutReportsSettelement = true;
    }

    if (pathname == 'cashout-operations/consignments') {
      this.openMap.sub7 = true;
      this.mCashoutConsignments = true;
    }

    if (pathname == '/extract') {
      this.openMap.sub6 = true;
      this.mExtract = true;
    }

    if (pathname == '/conciliation') {
      this.mConciliations = true;
    }
    if (pathname == '/customers') {
      this.mCustomers = true;
    }


    if (pathname == '/bank-acounts') {
      this.mBankAcounts = true;
    }

    if (pathname == '/configuration' || pathname == '/configuration-profile' || pathname == '/configuration-user-maintenance' || pathname == '/configuration-change-password' || pathname == '/configuration-api' || pathname == '/configuration-webhook' ) {
      this.mConfiguration = true;
    }

  }



  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

  marginNav() {
    let i = 0;
    let marginNav = setInterval(function () {
      if ($('.ng-star-inserted').length > 0) {
        // $('.ng-star-inserted').addClass('row');
        $('.ng-star-inserted span').addClass('spanNav');
        clearInterval(marginNav);
      } else {
        if (i === 50) {
          clearInterval(marginNav);
        }
        i++;
      }
    }, 200);
  }

  navigation(route) {
    console.log({ route });
    this.router.navigate(['/' + route]);
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.api.validateMerchantPermissions();
      }
    });
  }

}
