import { GlobalHttpInterceptorServiceService } from './global-http-interceptor-service.service';
import { ConsignmentsComponent } from './components/content/cashout/consignments/consignments.component';

import { CookieService } from 'ngx-cookie-service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { DashboardComponent } from './components/content/dashboard/dashboard.component';
import { NavComponent } from './components/partials/nav/nav.component';
import { HeaderComponent } from './components/partials/header/header.component';
import { FooterComponent } from './components/partials/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule, NZ_I18N, es_ES } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { MainMenuComponent } from './components/partials/main-menu/main-menu.component';

import { Model1Component } from './components/partials/tables/model1/model1.component';
import { Filter1Component } from './components/partials/filters/filter1/filter1.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';


import { Detail1Component } from './components/partials/details/detail1/detail1.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { PaymentsOrdersComponent } from './components/content/cashin/payments-orders/payments-orders.component';
import { SettlementComponent } from './components/content/cashin/reports/settlement/settlement.component';
import { DetailComponent } from './components/content/detail/detail.component';
import { ReportsComponent } from './components/content/cashout/reports/reports.component';

import { PaymentsComponent } from './components/content/cashin/reports/payments/payments.component';
import { ConciliationComponent } from './components/content/conciliation/conciliation.component';
import { ConfigurationComponent } from './components/content/dashboard/configuration/configuration.component';

import { ImportComponent } from './components/content/import/import.component';
import { OperationsComponent } from './components/content/cashout/operations/operations.component';
import { ProfileComponent } from './components/content/dashboard/configuration/profile/profile.component';
import { UserMaintenanceComponent } from './components/content/dashboard/configuration/user-maintenance/user-maintenance.component';
import { ChangePasswordComponent } from './components/content/dashboard/configuration/change-password/change-password.component';
import { ApiComponent } from './components/content/dashboard/configuration/api/api.component';
import { WebhookComponent } from './components/content/dashboard/configuration/webhook/webhook.component';
import { ApiService } from './services/api.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RecaptchaModule } from 'ng-recaptcha';
import { BankAcountsComponent } from './components/content/bank-acounts/bank-acounts.component';
import { PayrollComponent } from './components/content/cashout/payroll/payroll.component';
import { NewPayrollComponent } from './components/content/new-payroll/new-payroll.component';
import { RegisterConfirmationComponent } from './components/auth/register-confirmation/register-confirmation.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FilterCustomerComponent } from './components/partials/filters/filter-customer/filter-customer/filter-customer.component';
import { CustomerComponent } from './components/content/customer/customer/customer.component';
import { PaymentsOrdersCustomerComponent } from './components/content/cashin/payments-orders-customer/payments-orders-customer.component';
import { OperationsCustomerComponent } from './components/content/cashout/operations-customer/operations-customer.component';
import { ToolBarFooterComponent } from './components/partials/tool-bar-footer/tool-bar-footer.component';
import { ExtractComponent } from './components/content/reports/extract/extract.component';
import { ThousandsPipe } from './pipes/thousands.pipe';


registerLocaleData(es);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    NavComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    Model1Component,
    Filter1Component,
    Detail1Component,
    PaymentsOrdersComponent,
    SettlementComponent,
    DetailComponent,
    ReportsComponent,
    OperationsComponent,
    PaymentsComponent,
    ConciliationComponent,
    ConfigurationComponent,
    ImportComponent,
    ProfileComponent,
    UserMaintenanceComponent,
    ChangePasswordComponent,
    ApiComponent,
    WebhookComponent,
    BankAcountsComponent,
    PayrollComponent,
    NewPayrollComponent,
    RegisterConfirmationComponent,
    FilterCustomerComponent,
    CustomerComponent,
    PaymentsOrdersCustomerComponent,
    OperationsCustomerComponent,
    ToolBarFooterComponent,
    ExtractComponent,
    ConsignmentsComponent,
    ThousandsPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    NzMenuModule,
    NzTableModule,
    NzFormModule,
    NzModalModule,
    NzDatePickerModule,
    NzIconModule,
    NgxChartsModule,
    RecaptchaModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiService,
      multi: true,
    },
    {
      provide: NZ_I18N, useValue: es_ES
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorServiceService,
      multi: true
    }
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
