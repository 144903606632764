import { SessionService } from './session.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient, private session: SessionService) {}

  public get(endPoint: string, dataHeader?: HttpHeaders, params?: HttpParams) {
    const bearerToken = this.session.getToken();
    const headers =
      dataHeader ? dataHeader :
      new HttpHeaders()
        .set("Authorization", "Bearer " + bearerToken)
        .set("Content-Type", "application/json");

    return this.http.get(`${API}${endPoint}`, { params, headers });
  }

  public download(endPoint: string, dataHeader?: HttpHeaders, params?: HttpParams) {
    const bearerToken = this.session.getToken();
    const headers =
      dataHeader ? dataHeader :
      new HttpHeaders()
        .set("Authorization", "Bearer " + bearerToken)
        .set("Content-Type", "application/json");
    return this.http.get(`${API}${endPoint}`, { responseType: 'blob', headers, params});
  }
}
