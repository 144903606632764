import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';


@Component({
  selector: 'app-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.scss']
})
export class WebhookComponent implements OnInit {


  public titlePage: string = 'Configuraciones > Webhook';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public urlCashin: any;
  public urlCashout: any;
  serviceIdCashin: any;
  serviceIdCashout: any;
  uCashin = new FormControl('');
  uCashout = new FormControl('');

  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {

  }

  ngOnInit() {
    this.changeMerchant();
    this.getDataUrlCashin();
    this.getDataUrlCashout();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {
        this.merchant = m.data;
      }
    });
  }

  getDataUrlCashin() {

    this.urlCashin = '';


    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/webhook/' + this.merchant,
      data: ''
    }

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.urlCashin = res[0].NotificationURL;
      this.serviceIdCashin = res[0].ServiceId;
    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });


  }

  getDataUrlCashout() {

    this.urlCashout = '';

    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/webhook/' + this.merchant,
      data: ''
    }

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.urlCashout = res[1].NotificationURL;
      this.serviceIdCashout = res[1].ServiceId;
    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });


  }

  update(webhook,serviceId){
    var urlwebhook; 
    if(webhook == 'cashin'){
      urlwebhook = this.uCashin.value;
    }else{
      urlwebhook = this.uCashout.value;
    }

    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/webhook/' + this.merchant,
      data: {
        type: 'update',
        serviceid: serviceId,
        metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
        webhook: urlwebhook
      }
    }

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');

      if (webhook == 'cashin') {
        this.getDataUrlCashin();
      } else {
        this.getDataUrlCashout();
      }

      

    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }



}
