import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, FormControl } from "@angular/forms";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-filter1",
  templateUrl: "./filter1.component.html",
  styleUrls: ["./filter1.component.scss"],
})
export class Filter1Component implements OnInit {
  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  form: FormGroup;
  items: FormArray;
  dataFields: any;
  isVisible: boolean = false;
  date = null;
  dateRange = [];
  titleModal: any = "";
  buttonDownload: boolean = false;
  range: any = null;
  period: any = new FormControl("0");
  from_date = null;
  to_date = null;
  flag_status: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cookie: CookieService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.from_date = params.from_date;
      this.to_date = params.to_date;
      if( params.from_date || params.to_date ) 
        this.flag_status = true;
    });

    this.data.then((data: any) => {
      this.dataFields = data;
      let item = {};
      data.forEach((e: any) => {
        if (e.key == "download") {
          this.buttonDownload = true;
        }

        let fromDate = this.from_date ? this.from_date : moment().startOf("month").format("YYYY-MM-DD");
        let toDate = this.to_date ? this.to_date : moment().format("YYYY-MM-DD");

        console.log("from_Date", fromDate);
        console.log("to_date", toDate);
        console.log('e', e);
        if (Array.isArray(e.key)) {
          item["period"] = "3";
          item[e.key[0]] = fromDate;
          item[e.key[1]] = toDate;
        } else {
          if (e.type === "select") {
            item[e.key] = "";
            if(this.flag_status && e.key === 'status')
              item[e.key] = "12";
          } else {
            item[e.key] = "";
          }
        }
      });

      this.form = this.fb.group({
        items: this.fb.array([this.createItem(item)]),
      });
    });
  }

  onChangePeriod(e) {
    moment.locale("es", {
      week: {
        dow: 1,
        doy: 4,
      },
    });

    let fromDate = "";
    let toDate = moment().format("YYYY-MM-DD");

    switch (e.target.value) {
      case "0":
        fromDate = moment().format("YYYY-MM-DD");
        this.range = null;
        break;
      case "1":
        fromDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.range = null;
        break;
      case "2":
        fromDate = moment().weekday(0).format("YYYY-MM-DD");
        this.range = null;
        break;
      case "3":
        fromDate = moment().startOf("month").format("YYYY-MM-DD");
        this.range = null;
        break;
      case "4":
        fromDate = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.range = null;
        break;
      case "5":
        this.showModal();
        break;
      default:
        break;
    }

    console.log("FROM: ", fromDate);
    console.log("TO: ", toDate);

    let values = this.fields.controls[0].value;
    values.from_date = fromDate;
    values.to_date = toDate;

    if (e.target.value !== "5") {
      // this.fields.controls[0].setValue(values);
      // this.fields.controls[0] =  [...this.fields.controls , {value: values}];

      // this.fields.controls[0].setValue(values);
      // this.fields.controls[0].value = values;
      // this.process();

      this.dataOutput.emit(values);
    }
  }

  search(e) {
    this.dataOutput.emit({
      type: "search",
      data: e,
    });
  }

  processAditionalButton(e) {
    this.dataOutput.emit(e);
  }

  processAditionalButtonDownload(e: any) {
    this.dataOutput.emit({
      type: e,
      data: this.getData(),
    });
  }

  process() {
    this.dataOutput.emit(this.getData());
  }

  getData() {
    const dataValues = (<FormArray>this.form.get("items")).controls[0].value;
    return dataValues;
  }

  createItem(item: any): FormGroup {
    return this.fb.group(item);
  }

  get formData() {
    return (<FormArray>this.form.get("items")).controls;
  }

  get fields() {
    return this.form.get("items") as FormArray;
  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    this.process();
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //DATE PICKER

  onChangeDate(result: Date): void {
    let fromDate = moment(result[0]).format("YYYY-MM-DD");
    let toDate = moment(result[1]).format("YYYY-MM-DD");
    let values = this.fields.controls[0].value;
    values.from_date = fromDate;
    values.to_date = toDate;
    this.fields.controls[0].setValue(values);

    this.range = fromDate + " / " + toDate;
    this.period.setValue("-1");
  }
}
