import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  response: string = '';
  public isLogged: boolean = false;
  formLogin: FormGroup;
  validateCaptcha: Boolean = false;
  lang: any = 'es';

  constructor(private router: Router, private cookie: CookieService, private authService: AuthService, public translate: TranslateService) {
    this.formLogin = this.createFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      legal_number: new FormControl('', [Validators.required]),
      legal_name: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  handleErrorMessages()  {
    if (this.formLogin.value.email === '') {
      this.formLogin.controls.email.markAsDirty();
    }

    if (this.formLogin.value.legal_number === '') {
      this.formLogin.controls.legal_number.markAsDirty();
    }

    if (this.formLogin.value.legal_name === '') {
      this.formLogin.controls.legal_name.markAsDirty();
    }

    if (this.formLogin.value.telephone === '') {
      this.formLogin.controls.telephone.markAsDirty();
    }

    if (this.formLogin.value.description === '') {
      this.formLogin.controls.description.markAsDirty();
    }

  }

  onRegister(): void {

    if (this.formLogin.status === "INVALID") {
      this.handleErrorMessages();
      this.response = 'Ingrese todos los campos del formulario';
    } else if (this.validateCaptcha) {

      let data = {
        email: this.formLogin.get('email').value,
        legal_number: this.formLogin.get('legal_number').value,
        legal_name: this.formLogin.get('legal_name').value,
        phone: this.formLogin.get('telephone').value,
        description: this.formLogin.get('description').value,
        service: 'register'
      }

      this.authService.api(data).subscribe((result: any) => {
        console.log('Result Resgistration', result)
        if (result.status) {
          // this.cookie.set('ud', JSON.stringify(result));
          this.router.navigate(['/register-confirmation']);
        }else{
          this.response = '';
          if (result.message.legal_number){
            this.response += result.message.legal_number + ' , ';
          }
          if (result.message.phone) {
            this.response += result.message.phone + ' , ';
          }
          if (result.message.description) {
            this.response += result.message.description + ' , ';
          }

          
        }
      },
        error => {
          console.log(error);
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/login']);
          }
        });
    } else {
      this.response = 'Valide el recaptcha por favor';
    }

  }


  ngOnInit() {
    this.verifySession();
  }

  verifySession() {
    if (this.cookie.get('ud') != '') {
      this.isLogged = true;
      this.router.navigate(['/dashboard']);
    }
  }

  onResetForm() {
    this.formLogin.reset();
  }

  seePassword(e) {

    if ($('#password').attr('type') == 'password') {
      $('#password').attr('type', 'text');
    } else {
      $('#password').attr('type', 'password');
    }
  }


  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.validateCaptcha = true;
    }
  }

  changeLang(language) {
    this.lang = language
    this.translate.use(language);
  }

  get email() { return this.formLogin.get('email'); }
  get legal_number() { return this.formLogin.get('legal_number'); }
  get legal_name() { return this.formLogin.get('legal_name'); }
  get telephone() { return this.formLogin.get('telephone'); }
  get description() { return this.formLogin.get('description'); }


}
