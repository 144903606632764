import { SessionService } from './../../../../services/session.service';
import { ExtractService } from './../../../../services/extract.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import filters from "../../../../../assets/config/filters-extract.js";

@Component({
  selector: 'app-extract',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss']
})
export class ExtractComponent implements OnInit {
  formFilter: FormGroup;
  records: any;
  loading: boolean = true;
  total: number = 1;
  per_page: number = 10;
  current_page: number = 1;
  today = new Date();
  filters: any;
  totals: any;
  merchant: string = null;


  constructor(
    private fb: FormBuilder,
    private extractService: ExtractService,
    private sessionService: SessionService
  ) { 
    this.merchant = this.sessionService.getMerchantCode();
    this.filters = this.getFilters();
  }

  ngOnInit() {
    this.buildFormFilters();
    this.getTotals();
    this.getData();
  }

  getData() {
    this.extractService
      .getRecords(this.formFilter.value)
      .subscribe((response: any) => {
        this.records = response;
        this.loading = false;
      });
  }

  getFilters() {
    return new Promise((resolve, reject) => {
      resolve(filters);
    });
  }

  getTotals() {
    this.extractService
      .getTotals(this.formFilter.value)
      .subscribe((response: any) => this.totals = response);
  }

  buildFormFilters() {
    const year = this.today.getFullYear();
    const month = this.today.getMonth();
    this.formFilter = this.fb.group({
      from_date: new FormControl(
        `${month > 0 ? year : year - 1}-${month > 0 ? ("0" + (month).toString().slice(-2)) : 12}-${(
          "0" + this.today.getDate()
        ).slice(-2)}`
      ),
      to_date: new FormControl(""),
      page: new FormControl(""),
      size: new FormControl("10"),
      MerchantType: new FormControl("6"),
      MerchantCode: new FormControl(this.merchant)
    });
  }

  changeDataTable(props) {
    this.formFilter.controls.from_date.setValue(props.from_date);
    this.formFilter.controls.to_date.setValue(props.to_date);
    this.getData();
    this.getTotals();
  }

  downloadData() {
    this.extractService
      .downloadRecords(this.formFilter.value)
      .subscribe((response: any) => {
        var newBlob = new Blob([response]);
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        const date = new Date();
        link.download = `Extract.${date.toISOString()}.csv`
        link.href = url;
        link.click();        
      });
  }

  onPageIndexChange(page): void {
    this.formFilter.controls.page.setValue(page);
    this.getData();
    this.getTotals();
  }

  onPageSizeChange(size): void {
    this.formFilter.controls.size.setValue(size);
    this.getData();
    this.getTotals();
  }
}
