
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';


@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent implements OnInit {


  public titlePage = 'Configuraciones > API';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public key: any;
  apikey = new FormControl('');

  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {
  }

  ngOnInit() {

    this.changeMerchant();
    this.getKey();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.getKey();
      }

    });
  }

  getKey() {
    this.key = '';
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/secretkey/' + this.merchant,
      data: ''
    };

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.key = res.ApiKey;

    })
    .catch(e => {
      $('#charging').addClass('hide');
      if (e.status === 401) {
        this.cookie.set('ud', '');
        this.router.navigate(['/']);
      }
    });


  }

  updateKey() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/secretkey/' + this.merchant,
      data: {
        type: 'update',
        data: {
          secretkey: $('#userinput').val(),
          metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
        }
      }
    };

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.getKey();

    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status === 401) {
          console.log('ERROR',e);
          // this.cookie.set('ud', '');
          // this.router.navigate(['/']);
        }
      });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

}
