import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../../services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public isLogged = false;
  public path = '';
  public dataInput;

  constructor(
    private router: Router,
    private cookie: CookieService,
    private api: ApiService) { }

  ngOnInit() {
    this.verifySession();
    this.path = window.location.pathname.trim();
    this.api.validateMerchantPermissions();
  }

  verifySession() {
    if (this.cookie.get('ud') === '') {
      this.isLogged = true;
      this.router.navigate(['/login']);
    }
  }

  changeData(data) {
    switch (data.event) {
      case 'change-merchant':
        this.dataInput = data;
        this.api.validateMerchantPermissions();
        break;
      default:
        console.log('EVENT DATA', data);
        break;
    }
  }
}
