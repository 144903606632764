import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  urlCore = environment.api;

  constructor(private http: HttpClient, public router: Router) {}

  api(datos: any) {
    switch (datos.service) {
      case "auth/login":
        return this.http.post(`${this.urlCore + datos.service}`, datos);
      case "register":
        return this.http.post(`${this.urlCore + datos.service}`, datos);

      default:
        break;
    }
  }
}
