import { HttpService } from './http.service';
import { SessionService } from './session.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExtractService {

  constructor(
    private http: HttpService,
    private session: SessionService
  ) { }

  ngOnInit(): void {
  }

  getRecords(filters: any[]) {
    const params = this.setParams(filters);
    return this.http.get('extract', null, params);
  }

  getDetails(filters: any[]) {
    const params = this.setParams(filters);
    return this.http.get('extract/details', null, params);
  }

  downloadRecords(filters: any[]) {
    const params = this.setParams(filters);
    return this.http.download('extract/download', null, params);
  }

  getTotals(filters: any[]) {
    const params = this.setParams(filters);
    return this.http.get('extract/total', null, params);
  }

  setParams(filters: any[]) {
    let params = new HttpParams();

    for (const key in filters) {
      if (filters[key] !== '') {
        params = params.append(key, filters[key]);
      }
    }
    return params;
  }
}
